const theme = {
  siteBackground: '#272727',
  background: '#000',
  primary: '#FFF',
  accent: '#FF5D00',
  sm: '560px',
  md: '992px',
  lg: '1200px'
}

export default theme
import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'

import theme from '../styled/theme'

import 'normalize.css/normalize.css'
import './base.css'

const Wrapper = styled.main`
  margin: 0 auto;

  @media screen and (min-width: ${props => props.theme.md}){
    box-shadow: 
      0 20px 40px hsla(0, 0%, 0%, .3),
      0 15px 10px hsla(0, 0%, 0%, .2)
    ;
    width: 100vh;
  }
`

const Layout = props => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {props.children}
      </Wrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
